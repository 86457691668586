import { ActionReducer, ActionReducerMap, INIT, MetaReducer } from "@ngrx/store";
import { ItemsState } from "../interfaces/state/Item.state";
import { itemsReducer } from "./reducers/items.reducers";
import { userSesionReducer } from './reducers/user-sesion.reducers';
import { UserSesionState } from '../interfaces/state/user-sesion.state';
import { PreselectaState } from "../interfaces/state/preselecta.state";
import { preselectaReducer } from "./reducers/preselecta.reducers";
import { logout } from "./actions/user-sesion.action";

export interface AppState {
    items: ItemsState;
    userSesion: UserSesionState;
    preselecta: PreselectaState
}

export const ROOT_REDUCERS: ActionReducerMap<AppState> = {
    items: itemsReducer,
    userSesion: userSesionReducer,
    preselecta: preselectaReducer,
}

export const metaLogout = (reducer: ActionReducer<any>): ActionReducer<any> => {
    return (state, action) =>  {
        if(action.type === logout.type){
            return reducer(undefined, { type: INIT })
        }
        return reducer(state, action)
    }
}

export const metaReducers: MetaReducer<AppState>[] = [
    metaLogout
]