// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
let domain: string = location.origin
export const environment = {
  production: false,
  api: 'http://api.develop.portalkreditime.com/',
  client: `${domain}/`,
  googleMaps: 'AIzaSyBcMvJb7zdnjOAkNGZa2fl6wPLkEL_Ufmg',
  exemptAuth: [
    'authentication',
    'verify/msm_verification',
    '/image_processing_validation/ID_front_verification',
    '/image_processing_validation/ID_back_verification'
  ],

  requestFile:[
    "/image_processing_validation/ID_front_verification",
    "/image_processing_validation/ID_back_verification"
  ],
  recaptcha: {
    siteKey: "6LcOX2ogAAAAAD1CoxT989_lFh_uzYS4HKqQXS0W"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
