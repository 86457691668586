import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthService } from './../services/auth.service';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { Token } from './../interfaces/token';
import {environment} from '../../environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authService: AuthService) {
    console.log('Interceptor');
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log(request.url);
    // && this.urlIsAuthExempt(request)
    if(this.authService.getAccessToken() && this.urlIsAuthExempt(request)){
      request = this.addToken(request, this.authService.getAccessToken());
    }

    return next.handle(request).pipe(catchError(error => {
      if (error instanceof HttpErrorResponse && error.status === 401 && this.urlIsAuthExempt(request)) {
        return this.handle401Error(request, next);
      } else {
        return throwError(error);
      }
    }));
  }

  private addToken(request: HttpRequest<any>, token: any){
    const isFileRequest =  environment.requestFile.find((item) => request.url.includes(item))
    console.log("el valor es", isFileRequest)
    if(isFileRequest){
      console.log("es archivoo")
      return request.clone({
        setHeaders: {
          'Authorization': `Bearer ${token}`
        }
      });
    }else{
      console.log("No es archivoo")
      return request.clone({
        setHeaders: {
          'Content-Type': 'application/json; charset=utf8',
          'Authorization': `Bearer ${token}`
        }
      });
    }
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
          switchMap((token: Token) => {
            console.log(token);
            this.isRefreshing = false;
            this.refreshTokenSubject.next(token.access);
            return next.handle(this.addToken(request, token.access));
          }));

    } else {
      return this.refreshTokenSubject.pipe(
          filter(token => token != null),
          take(1),
          switchMap(access => {
            return next.handle(this.addToken(request, access));
          }));
    }
  }

  private urlIsAuthExempt(request: HttpRequest<any>){
    return environment.exemptAuth.find((item) => request.url.includes(item))
  }
}
