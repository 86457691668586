import { createReducer, on } from '@ngrx/store';
import { PreselectaState } from "src/app/interfaces/state/preselecta.state";
import { respPreselecta } from '../actions/preselecta.action';


export const initialState: PreselectaState = { 
    cupo: '0',
    disponible: '0',
    stattus: '',
}

export const preselectaReducer = createReducer(
initialState,
on(respPreselecta, (state , {preselectaResp}) => { 
    console.log("lo que llego del servicio", preselectaResp)
    let preselectaValues: PreselectaState = {cupo: '', disponible: '', stattus: '',}
    for(let i = 0; i < preselectaResp.body['engineResponse'].length; i++){
        if(preselectaResp.body['engineResponse'][i].key === 'DECISION'){
           preselectaValues.stattus  = preselectaResp.body['engineResponse'][i].value;
          }
          if(preselectaResp.body['engineResponse'][i].key === 'DISPONIBLE'){
           preselectaValues.disponible = preselectaResp.body['engineResponse'][i].value;
          }
         
      }
return { ...state, disponible: preselectaValues.disponible, stattus: preselectaValues.stattus}
}),

);