//import { ShowCaseService } from '@modules/show-case/services/show-case.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects'; //TODO <---
import { type } from 'os';
import { EMPTY, of } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';

@Injectable()
export class PreselectaEffects {

    constructor(
        private actions$: Actions,
        private authService :AuthService
    ) { }

    PreselectaInfo$ = createEffect(() => this.actions$.pipe(
        ofType('[preselecta] preselecta'),
        mergeMap((action)=>{
           const { person } = action
           console.log("disparando el serviciooo")
           return this.authService.preselecta(person).pipe(
                map((preselectaResp) => ({ type: '[preselecta] preselecta respuesta', preselectaResp })),
               // tap((action) => this.router.navigate(['/contacts', action.payload.id]))
                catchError(({error}) =>{
                console.log("effects errors", error)
                return of({ type:'[User sesion] error sesion', sesionError: error })
            }
                )
            )
        })
    )
    );

    

    
}