import { createAction, props } from '@ngrx/store'; //TODO: <----
import { Token } from 'src/app/interfaces/token';
 
import { Credentials, ErrorSession } from '../../interfaces/state/user-sesion.interface';

export const loadUserSesion = createAction(
    '[User sesion] load sesion',
    props<{ credentials: Credentials }>()
);

export const loadUserSesionBackground = createAction(
    '[User sesion] load sesion backgroud',
    props<{ credentials: Credentials }>()
);

export const loadedSesion = createAction(
    '[User sesion] loaded sesion',
    props<{ sesionInfo: Token }>()
)

export const loadedSesionBackground = createAction(
    '[User sesion] loaded sesion background',
    props<{ sesionInfo: Token }>()
)

export const logout = createAction(
    '[User sesion] logout sesion'
)

export const errorSesion = createAction(
    '[User sesion] error sesion',
    props<{ sesionError: ErrorSession }>()
)
