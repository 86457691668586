import { createAction, props } from '@ngrx/store'; //TODO: <----
import { ItemModel } from 'src/app/interfaces/state/Item.interface';

export const loadItems = createAction(
    '[Item List] Load items', //TODO type*,
    props<{ username: string; password: string }>()
);

export const loadedItems = createAction(
    '[Item List] Loaded success',
    props<{ items: ItemModel[] }>()
)