//import { ShowCaseService } from '@modules/show-case/services/show-case.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects'; //TODO <---
import { type } from 'os';
import { EMPTY, of } from 'rxjs';
import { map, mergeMap, catchError, tap } from 'rxjs/operators';
import { Token } from 'src/app/interfaces/token';
import { AuthService } from '../../services/auth.service';

@Injectable()
export class UserSesionEffects {

    constructor(
        private actions$: Actions,
        private authService :AuthService,
        private router: Router,
        public modalController: ModalController
    ) { }

    loadSesionInfo$ = createEffect(() => this.actions$.pipe(
        ofType('[User sesion] load sesion'),
        mergeMap((action)=>{
           const { credentials } = action
           return this.authService.loginUser(credentials).pipe(
                map((sesionInfo: Token) => {
                    this.authService.doLoginUser(sesionInfo)
                    this.modalController.dismiss()
                    this.router.navigate(['perfil']);
                    return ({ type: '[User sesion] loaded sesion', sesionInfo })}
                ),
             //   tap((action) => this.router.navigate(['/contacts', action.payload.id])),
                catchError(({error}) =>{
                console.log("effects errors", error)
                return of({ type:'[User sesion] error sesion', sesionError: error })
            }
                )
            )
        })
    )
    );

    
    loadSesionInfoBackground$ = createEffect(() => this.actions$.pipe(
        ofType('[User sesion] load sesion backgroud'),
        mergeMap((action)=>{
           const { credentials } = action
           return this.authService.loginUser(credentials).pipe(
                map((sesionInfo: Token) => {
                    this.authService.doLoginUser(sesionInfo)
                    return ({ type: '[User sesion] loaded sesion background', sesionInfo })}
                ),
                catchError(({error}) =>{
                console.log("effects errors", error)
                return of({ type:'[User sesion] error sesion', sesionError: error })
            }
                )
            )
        })
    )
    );
  

    
}