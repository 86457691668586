import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, ExtraOptions  } from '@angular/router';

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
  preloadingStrategy: PreloadAllModules,
  enableTracing: false,
  useHash: true
};

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./components/pages/client/client.module').then( m => m.ClientPageModule)
  },
  // {
  //   path: 'admin',
  //   loadChildren: () => import('./components/pages/admin/admin.module').then( m => m.AdminPageModule)
  // },
  {
    path: 'perfil',
    loadChildren: () => import('./components/pages/profile/profile.module').then( m => m.ProfilePageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, routerOptions)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
