import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { Credentials } from '../interfaces/credentials'
import { tap, mapTo, catchError } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { Token } from '../interfaces/token';
// import { config } from './../config';
// import jwt_decode from 'jwt-decode';
import { UsersService } from './users.service';
import {environment} from '../../environments/environment';
import {Person} from '../interfaces/person';
import {Answers} from "../interfaces/answers";
import { Login } from '../interfaces/Login';
import { Questions } from '../interfaces/Questions';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  person: Person = {
    email: '',
    names: '',
    name:"",
    last_names: '',
    last_name: '',
    document_type: null,
    document: '',
    phone: null,
    password: '',
    confirm_password: '',
    central_risk: false,
    accept_policies: false,
    document_exp_date: '',
    otpConfirmed: false
  };

  token: string;

  private readonly ACCESS_TOKEN = 'ACCESS_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
  private readonly PERSON = 'person'
  // user_id : number
  // name : string

  API: string = '';
  HEADER: HttpHeaders = new HttpHeaders;

  constructor(
      private http: HttpClient
  ) {
    this.API = environment.api;
  }

  // login(user:Credentials): Observable<Token | Boolean>{
  //   return this.http.post<any>(`${config.apiUrl}/authentication`, user).pipe(
  //     tap((tokens:Token) => this.doLoginUser(tokens))
  //   )
  // }
  //
  // logout() {
  //   return this.http.post<any>(`${config.apiUrl}/authentication/logout`,{
  //     refresh_token : this.getRefreshToken()
  //   }).pipe(
  //     tap(() => this.doLogoutUser()),
  //     mapTo(true),
  //     catchError(error => {
  //       console.log(error);
  //       return of(false);
  //     })).subscribe()
  // }
  //
  refreshToken() {
    return this.http.post<Token>(`${this.API}/user_auth/token_refresh`, {
      'refresh': this.getRefreshToken()
    }).pipe(tap((tokens: Token) => {
      this.storeAccessToken(tokens);
    }));
  }
  getAccessToken() {
    return localStorage.getItem(this.ACCESS_TOKEN);
  }

  // getUser() {
  //   let user = {user_id : this.user_id, name : this.name } = jwt_decode( this.getAccessToken() || '')
  //   return user.name
  // }

  register(people: Person){
    return this.http.post(this.API + 'user_auth/registration', people, { observe: 'response' });
  }
  emailCode(email: string){
    return this.http.post(this.API + '/otp_validation/email_verification', {'email': email}, { observe: 'response' });
  }
  msmCode(telephoneNumber: number){
    return this.http.post(this.API + '/otp_validation/msm_verification', {'telephone_number': telephoneNumber}, { observe: 'response' });
  }
  emailVerification(id: number, token: string){
    return this.http.get(this.API + '/otp_validation/email_verification/'+id+'/'+token, { observe: 'response' });
  }
  msmVerification(id: number, token: string){
    return this.http.get(this.API + '/otp_validation/msm_verification/'+id+'/'+token, { observe: 'response' });
  }
  preselecta(people: Person){
    return this.http.post(this.API + 'datacredit/preselecta', people, { observe: 'response' });
  }
  faceDetect(instantPhoto: File){
    const formData = new FormData();
    formData.append('photo', instantPhoto);
    return this.http.post(this.API+'/verify/face_detect', formData, { observe: 'response' });
  }

  
imageValidation(face: File, document: File){
  const formData = new FormData();
  formData.append('person_photo', face, face.name)
  formData.append('document_photo', document, document.name)
  return this.http.post(this.API + '/image_processing_validation/ID_front_verification', formData, { observe: 'response' })
}
imageValidationReverse( documentReverse: File, dateExp: string){
  const formData = new FormData();
  formData.append('document_photo', documentReverse, documentReverse.name)
  formData.append('document_exp_date', dateExp)
  return this.http.post(this.API + '/image_processing_validation/ID_back_verification', formData, { observe: 'response' })
}
  // eslint-disable-next-line @typescript-eslint/naming-convention
  compare(face_token1: string, face_token2: string){
    return this.http.post(this.API+'/verify/face_verification_tokens', {'face_token1': face_token1, 'face_token2': face_token2}, { observe: 'response' });
  }
  login(people: Person){
    return this.http.post(this.API+'/datacredit/evidente/login', people, { observe: 'response' });
  }

  loginUser(credentials: Login){
    return this.http.post(this.API+'/user_auth/token_optain_pair', credentials );
  }

  logoutUser(){
    localStorage.removeItem(this.ACCESS_TOKEN)
    localStorage.removeItem(this.PERSON)
    localStorage.removeItem(this.REFRESH_TOKEN)
  }

  questions(people: Person){
    return this.http.post<Questions>(this.API+'/datacredit/evidente/questions', people);
  }
  answers(answers: Answers){
    return this.http.post(this.API+'/datacredit/evidente/answers', answers, { observe: 'response' });
  }
  // compare(instantPhoto: File, documentPhoto: File){
  //   const formData = new FormData();
  //   formData.append('instant_photo', instantPhoto);
  //   formData.append('document_file', documentPhoto);
  //   return this.http.post(this.API+'/verify/face_verification', formData, { observe: 'response' });
  // }
  getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }
  isLoggedIn(){
    return !!this.getAccessToken();
  }
  doLoginUser(tokens: Token){
    this.storeTokens(tokens);
  }
  private doLogoutUser(){
    this.removeTokens();
  }
  private storeTokens(tokens: Token){
    localStorage.setItem(this.ACCESS_TOKEN, tokens.access);
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refresh);
  }
  private removeTokens(){
    localStorage.removeItem(this.ACCESS_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }
  private storeAccessToken(tokens: Token){
    localStorage.setItem(this.ACCESS_TOKEN, tokens.access);
  }

  // headers(){
  //   this.HEADER = new HttpHeaders({
  //     'Authorization': this.TOKEN,
  //     'X-XSS-Protection': '1;mode=block',
  //     'X-Frame-Options': 'DENY',
  //     'X-Content-Type-Options': 'nosniff',
  //   });
  // }
}
