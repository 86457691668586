import { createAction, props } from '@ngrx/store';
import { Person } from 'src/app/interfaces/person';


export const consultPreselecta = createAction(
    '[preselecta] preselecta',
    props<{ person:Person }>()
);

export const respPreselecta = createAction(
    '[preselecta] preselecta respuesta',
    props<{ preselectaResp: any }>()
);

