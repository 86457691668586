import { loadedItems } from './../actions/items.actions';
import { createReducer, on } from '@ngrx/store'; //TODO <----
import { loadItems } from '../actions/items.actions';
import { ItemsState } from 'src/app/interfaces/state/Item.state';
import { loadUserSesion, loadedSesion, errorSesion, loadedSesionBackground, logout } from '../actions/user-sesion.action';
import { SessionState } from 'http2';
import { UserSesionState } from 'src/app/interfaces/state/user-sesion.state';

export const initialState: UserSesionState = { 
                                                loading: false, 
                                                sesionInfo: { access:'', refresh:'', backgroud: false},
                                                error: null,
                                                isLoggedIn: false
                                            }

export const userSesionReducer = createReducer(
    initialState,
    on(loadUserSesion, (state) => { 
        return { ...state, loading: true }
    }),
    on(loadedSesion, (state, { sesionInfo }) => { 
        return { ...state, loading: false, sesionInfo , isLoggedIn: true}
    }),
    on(loadedSesionBackground, (state, { sesionInfo }) => { 
        return { ...state, loading: false, sesionInfo: { ...sesionInfo, backgroud: true} , isLoggedIn: true}
    }),
    on(errorSesion, (state, { sesionError }) => { 
        console.log("error reducer", sesionError)
        return { ...state, loading: false, error: sesionError }
    }),
);