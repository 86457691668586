import { RecaptchaModule } from 'ng-recaptcha';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';


import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TokenInterceptor} from './interceptors/token.interceptor';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { metaReducers, ROOT_REDUCERS } from './state/app.state';
import { ItemsEffects } from './state/effects/items.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { UserSesionEffects } from './state/effects/user-sesion.effects';
import { PreselectaEffects } from './state/effects/preselecta.effects';


@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    RecaptchaModule,
    AngularEditorModule,
    StoreModule.forRoot(ROOT_REDUCERS,{ metaReducers }),
    StoreDevtoolsModule.instrument({ name: 'TEST' }),
    EffectsModule.forRoot([ItemsEffects, UserSesionEffects,PreselectaEffects])//TODO: <-----
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
